/* eslint-disable */
import { pathOr } from 'ramda';
import { IncomeSummaryReduxState } from '@qwealth/qdata';

export type State = {
  incomeSummary?: IncomeSummaryReduxState;
};

export const selectIncomeSummaryRoot: (s: State) => IncomeSummaryReduxState | {} = pathOr(
  {},
  ['incomeSummary']
);
