import React from 'react';
import { Button, Col, Table as BSTable, TableProps as BSTableProps, Row } from 'react-bootstrap';
import { Box, styled, themeGet } from '@qwealth/qcore';

export interface TableAction {
  as?: React.ComponentClass;
  iconComponent?: React.ComponentClass | React.FC;
  id: string;
  label: React.ReactNode;
  [key: string]: any;
}

export interface RowData extends Record<string, any> {}

export interface Column {
  className?: string;
  colSpan?: number;
  format?: (v: any, rowIndex: number, rowData: any) => React.ReactNode;
  id: string;
  label: React.ReactNode;
  style?: {};
  tdClassName?: string;
}

export interface GroupHeader extends Column {}

export interface TableProps extends BSTableProps {
  actions?: Array<TableAction>;
  columns: Array<Column>;
  data: Array<RowData>;
  groupHeaders?: Array<GroupHeader>;
  hideHeaders?: boolean;
}

const renderTitleAndAction = (title?: React.ReactNode, actions?: Array<TableAction>) => {
  if (!title && !actions) {
    return null;
  }

  return (
    <Row>
      {title && (
        <Col>
          <Box fontWeight="bold" p="default" style={{ textTransform: 'uppercase' }}>
            {title}
          </Box>
        </Col>
      )}
      {actions && (
        <Col className="d-flex justify-content-end">
          {actions.map(({ as: Action = Button, iconComponent: Icon, id, label, ...props }) => (
            <Action key={id} {...props}>
              {Icon && <Icon />} {label}
            </Action>
          ))}
        </Col>
      )}
    </Row>
  );
};

const renderColumns = (columns: Array<Column | GroupHeader>) => {
  if (!columns) {
    return null;
  }

  /**
   * Convert <th> section below to reusable styled component similar to footer
   */
  return (
    <tr>
      {columns.map(({ format, id, label, tdClassName, ...props }) => (
        <th key={id} {...props}>
          {label}
        </th>
      ))}
    </tr>
  );
};

const renderHeaders = (
  columns: Array<Column>,
  groupHeaders?: Array<GroupHeader>,
  hideHeaders?: boolean,
) => (
  <thead className={hideHeaders ? 'd-none' : ''}>
    {groupHeaders && renderColumns(groupHeaders)}
    {renderColumns(columns)}
  </thead>
);

const StyledBSTable = styled(BSTable)`
  vertical-align: middle;

  thead {
    th {
      border: none;
      text-transform: uppercase;
      letter-spacing: 0.4px;
      padding: ${themeGet('space.default')};
      font-size: ${themeGet('fontSizes.subText')};
      background-color: ${themeGet('colors.gray.4')};
      color: ${themeGet('colors.gray.1')};
    }
  }

  tbody {
    td {
      padding: ${themeGet('space.default')};
    }
  }
`;

const StyledTR = styled.tr`
  color: #89868c;
`;

const StyledTFoot = styled.tfoot`
  background-color: ${themeGet('colors.gray.4')};
  font-family: 'TeX-Gyre-Adventor' !important;
  font-size: 16px;
  font-weight: bold;

  td {
    padding: ${themeGet('space.default')};
  }
`;

const StyledTableFooterLabel = styled.td`
  font-size: ${themeGet('fontSizes.subText')};
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.4px;
`;

// @ts-ignore
export const StyledTableSummary = ({ colSpan = 1, children, footerLabel }) => (
  <StyledTFoot>
    <tr>
      <StyledTableFooterLabel colSpan={colSpan}>{footerLabel}</StyledTableFooterLabel>
      {children}
    </tr>
  </StyledTFoot>
);

const renderData = (columns: Array<Column>, data: Array<RowData>) => (
  <tbody>
    {data.map((rowData, rowIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <StyledTR key={`row_${rowIndex}`}>
        {columns.map(({ format, id, tdClassName }) => (
          <td className={tdClassName} key={id}>
            {format ? format(rowData[id], rowIndex, rowData) : rowData[id]}
          </td>
        ))}
      </StyledTR>
    ))}
  </tbody>
);

const Table: React.FC<TableProps> = ({
  actions,
  children,
  columns,
  data,
  groupHeaders,
  hideHeaders = false,
  title,
  ...props
}) => (
  <div>
    {renderTitleAndAction(title, actions)}
    <StyledBSTable size="sm" {...props}>
      {renderHeaders(columns, groupHeaders, hideHeaders)}
      {renderData(columns, data)}
      {children}
    </StyledBSTable>
  </div>
);

export default Table;
