// @ts-nocheck
import React from 'react';
import { Card, useDispatchOnMount } from '@qwealth/qcore';
import { getMonthOverMonth } from '@qwealth/qdata';

import BudgetVsActual from './BudgetVsActual';
import AnnualBudget from './AnnualBudget';
import ItemizedSpendingGoals from './ItemizedSpendingGoals';
import ItemizedSavingGoals from './ItemizedSavingGoals';
import { selectAuthenticatedUsersHousehold } from '../../../data/selectors/userSelectors';
import { useSelector } from 'react-redux';

const MonthOverMonth = () => {
  const householdId = useSelector(selectAuthenticatedUsersHousehold);
  useDispatchOnMount(getMonthOverMonth, householdId);

  return (
    <Card className="content-container">
      <BudgetVsActual />
      <AnnualBudget />
      <ItemizedSpendingGoals />
      <ItemizedSavingGoals />
    </Card>
  );
};

export default MonthOverMonth;
