import React from 'react';
import { AccordionSection, Block, Box, toCurrency } from '@qwealth/qcore';

import FormValue from 'components/common/FormValue';
import {Transaction} from '@qwealth/qdata';

type Props = {
  deposits?: Array<Transaction>;
};

const Deposits: React.FC<Props> = ({ deposits = [] }) => (
  <AccordionSection
    backgroundColor="gray.4"
    collapsed={false}
    color="dark"
    fontSize="default"
    title="Deposits"
  >
    <Box display="grid" rowGap="default">
      {deposits.map((deposit) => (
        <Block key={deposit.guid}>
          <Box textStyle="formLabel">{deposit.category}</Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Source</Box>
            <FormValue>{deposit.source}</FormValue>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Description</Box>
            <FormValue>{deposit.description}</FormValue>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Amount</Box>
            <FormValue>{toCurrency(deposit.amount)}</FormValue>
          </Box>
        </Block>
      ))}
    </Box>
  </AccordionSection>
);

export default Deposits;
