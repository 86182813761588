// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { toCurrency } from '@qwealth/qcore';

import Table, { StyledTableSummary } from 'components/common/Table';
import { selectCombinedTaxableIncomesByProfileId } from 'data/selectors/income-summary/annualizedDeductionsSelectors';
import {
  selectCombinedDeductionsAndAdjustmentsWithRRSPAndCppQppByProfileId,
  selectCombinedSumDeductionsAndAdjustmentsByProfileId,
} from 'data/selectors/income-summary/deductionsAndAdjustmentsSelectors';

const COLUMNS = [
  {
    id: 'name',
    label: 'Deductions and Adjustments',
  },
  {
    className: 'text-right',
    format: (v?: number) => toCurrency(v),
    id: 'amount',
    label: 'Amount',
    style: { width: '250px' },
    tdClassName: 'text-right',
  },
];

const DeductionsAndAdjustments = ({ profileId }) => {
  const deductionsAndAdjustments = useSelector(
    selectCombinedDeductionsAndAdjustmentsWithRRSPAndCppQppByProfileId,
  );
  const totalDeductionsAndAdjustments = useSelector(
    selectCombinedSumDeductionsAndAdjustmentsByProfileId,
  );
  const taxableIncome = useSelector(selectCombinedTaxableIncomesByProfileId);

  return (
    <Table
      columns={COLUMNS}
      data={deductionsAndAdjustments[profileId] || []}
      title="Net Income Deductions And Adjustments"
    >
      <StyledTableSummary footerLabel="Taxable Income">
        <td className="text-right">
          <b>{toCurrency(taxableIncome[profileId])}</b>
        </td>
      </StyledTableSummary>
      <StyledTableSummary footerLabel="Total Deductions And Adjustments">
        <td className="text-right">
          <b>{toCurrency(totalDeductionsAndAdjustments[profileId])}</b>
        </td>
      </StyledTableSummary>
    </Table>
  );
};

export default DeductionsAndAdjustments;
