/* eslint-disable */
// @ts-nocheck
import { compose, filter, map, mapObjIndexed, prop, propEq, propOr, sum } from 'ramda';
import { createSelector } from 'reselect';
import { IncomeSource, IncomeSummaryReduxState, calculateTaxableIncome } from '@qwealth/qdata';

import { when } from 'utils/function';
import { sumAmount, isListNotEmpty, injectCombinedData, injectCombinedSumValue } from '../utils';

import { selectIncomeSummaryRoot, State } from './incomeSummarySelectors';

export const selectIncomeSourcesByProfileId = compose<
  State,
  IncomeSummaryReduxState | {},
  Record<string, Array<IncomeSource>>
>(propOr({}, 'incomeSources'), selectIncomeSummaryRoot);

export const selectCalculatedIncomeSourcesByProfileId = createSelector(
  selectIncomeSourcesByProfileId,
  mapObjIndexed((incomeSources) =>
    incomeSources.map(({ amount, name }) => ({
      amount,
      name,
      taxableAmount: calculateTaxableIncome({
        incomeSource: amount,
        incomeType: name
      })
    }))
  )
);

export const selectCombinedIncomeSourcesByProfileId = createSelector(
  selectCalculatedIncomeSourcesByProfileId,
  injectCombinedData('amount', 'taxableAmount')
);

// @ts-ignore
export const selectSumTaxableAmountsByProfileId = createSelector(
  // @ts-ignore
  selectCalculatedIncomeSourcesByProfileId,
  sumAmount('taxableAmount')
);

export const selectCombinedSumTaxableAmountsByProfileId = createSelector(
  selectSumTaxableAmountsByProfileId,
  injectCombinedSumValue
);

const isTypeEqualTo = propEq('name');
const isEarnedIncome = (incomeSource: IncomeSource): boolean =>
  isTypeEqualTo('Employment Income')(incomeSource) ||
  isTypeEqualTo('Bonus Income')(incomeSource) ||
  isTypeEqualTo('Self Employment Income')(incomeSource) ||
  isTypeEqualTo('Other Employment Income')(incomeSource);

export const selectEarnedIncomeByProfileId = createSelector(
  selectIncomeSourcesByProfileId,
  mapObjIndexed(when(isListNotEmpty, compose(sum, map(prop('amount')), filter(isEarnedIncome))))
);

export const selectHasCppQppIncomeSourcesByProfileId = createSelector(
  selectIncomeSourcesByProfileId,
  mapObjIndexed(compose(isListNotEmpty, filter(isTypeEqualTo('CPP/QPP'))))
);

export const selectTaxableEligibleDividendsByProfileId = createSelector(
  selectCalculatedIncomeSourcesByProfileId,
  mapObjIndexed(
    compose(
      map(prop<string, number>('taxableAmount')),
      // @ts-ignore
      filter(isTypeEqualTo('Eligible Dividends'))
    )
  )
);

export const selectTaxableIneligibleDividendsByProfileId = createSelector(
  selectCalculatedIncomeSourcesByProfileId,
  mapObjIndexed(
    compose(
      map(prop<string, number>('taxableAmount')),
      // @ts-ignore
      filter(isTypeEqualTo('Ineligible Dividends'))
    )
  )
);

export const selectTaxableAmountOldAgeSecuritiesByProfileId = createSelector(
  selectCalculatedIncomeSourcesByProfileId,
  mapObjIndexed(
    compose(
      map(prop<string, number>('taxableAmount')),
      // @ts-ignore
      filter(isTypeEqualTo('Old Age Security (OAS)'))
    )
  )
);
