import { compose, prop, propOr, values, mapObjIndexed } from 'ramda';
import { createSelector } from 'reselect';
import { sumProp, selectPersonsRoot } from '@qwealth/qdata';

import { PersonWithRRSP } from '../data-layer/person';
import { Household } from '../data-layer/household';

type State = {
  household?: Household;
};

export const selectHouseholdRoot: (s: State) => Household | {} = propOr({}, 'household');

// @ts-ignore
export const selectCurrentHouseholdId = compose<State, Household | {}, string>(
  prop<string, any>('id'),
  selectHouseholdRoot
);

export const selectCurrentHousehold = selectHouseholdRoot;

export const selectCurrentHouseholdProfiles = selectPersonsRoot;

// @ts-ignore
export const selectCurrentHouseholdProfileAsList: (
  s: State
  // @ts-ignore
) => Array<PersonWithRRSP> = createSelector(selectCurrentHouseholdProfiles, values);

export const selectPersonalRRSPContributionAmountByProfileId = createSelector(
  selectCurrentHouseholdProfiles,
  mapObjIndexed<PersonWithRRSP, 'Max' | number | undefined, string>(
    compose(
      // FIXME - handle dollar amount
      sumProp('amount'),
      // @ts-ignore
      prop('rrsps')
    )
  )
);
