// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { toCurrency } from '@qwealth/qcore';

import Table, { StyledTableSummary } from 'components/common/Table';
import {
  selectCombinedIncomeSourcesByProfileId,
  selectCombinedSumTaxableAmountsByProfileId,
} from 'data/selectors/income-summary/incomeSourceSelectors';

const COLUMNS = [
  {
    id: 'name',
    label: 'Income Source',
  },
  {
    className: 'text-right',
    format: (v?: number) => toCurrency(v),
    id: 'amount',
    label: 'Amount',
    style: { width: '130px' },
    tdClassName: 'text-right',
  },
  {
    className: 'text-right',
    format: (v?: number) => toCurrency(v),
    id: 'taxableAmount',
    label: 'Taxable Amount',
    style: { whiteSpace: 'nowrap' },
    tdClassName: 'text-right',
  },
];

const IncomeSources = ({ profileId }) => {
  const incomeSourcesByProfileId = useSelector(selectCombinedIncomeSourcesByProfileId);
  const totalTaxableAmountByProfileId = useSelector(selectCombinedSumTaxableAmountsByProfileId);
  return (
    <Table columns={COLUMNS} data={incomeSourcesByProfileId[profileId] || []} title="Income">
      <StyledTableSummary colSpan={2} footerLabel="Total Income Before Taxes">
        <td className="text-right">
          <b>{toCurrency(totalTaxableAmountByProfileId[profileId])}</b>
        </td>
      </StyledTableSummary>
    </Table>
  );
};

export default IncomeSources;
