import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Box, Card, toCurrency } from '@qwealth/qcore';
import {
  toYearAndMonth,
  MonthlyActual
} from '@qwealth/qdata';

import { selectSortedMonthlyActuals } from 'data/selectors/monthlyActualsSelectors';
import { selectAnnualBudget } from 'data/selectors/monthOverMonthSelectors';

const BudgetVsActual = () => {
  const navigate = useNavigate();
  const actuals = useSelector(selectSortedMonthlyActuals);
  // @ts-ignore
  const monthlyBudget = useSelector(selectAnnualBudget) / 12;
  // Having issue with MonthlyActuals[], so used any[] below. Need to fix.
  const labels = (actuals as any[]).map((actual: MonthlyActual) =>
    format(parseISO(actual.date), 'MMM'),
  );
  const actualsValues = (actuals as any[]).map((actual: MonthlyActual) => actual.value);

  return (
    <Box mb="large">
      <Card title="Budget vs Actual">
        <Box flex="1 1 auto" mt="large">
          <Bar
            height={90}
            data={{
              labels,
              datasets: [
                {
                  backgroundColor: '#5E1541',
                  barPercentage: 0.2,
                  data: actualsValues,
                  fill: true,
                  label: 'Monthly Actuals',
                },
                {
                  borderColor: '#79B94E',
                  borderDash: [5, 15],
                  data: new Array(actualsValues.length).fill(monthlyBudget),
                  fill: false,
                  label: 'Goal',
                  type: 'line',
                },
              ],
            }}
            options={{
              legend: {
                display: false,
              },
              onClick: (evt, element) => {
                if (element.length) {
                  const actual: MonthlyActual = actuals[element[0]._index];
                  // navigate off from the current view and allow clean up to happen without
                  // onClick function holding off a reference while the chart is being cleaned up
                  setTimeout(() => navigate(`monthovermonth/${toYearAndMonth(actual.date)}`));
                }
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                    ticks: {
                      callback: (value: number) => `$${value}`,
                    },
                  },
                ],
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItem: any, data: any) =>
                    `${data.datasets[tooltipItem.datasetIndex].label}: ${toCurrency(
                      tooltipItem.yLabel,
                    )}`,
                },
              },
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default BudgetVsActual;
