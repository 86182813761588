/* eslint-disable */
// @ts-nocheck
import { getMonth, isAfter, isThisYear, parseISO, set, subMonths } from 'date-fns';
import { isSameYear } from 'date-fns/fp';
import {
  compose,
  filter,
  prop,
  reduce,
  range,
  partialRight,
  take,
  not,
  isNil,
  values
} from 'ramda';
import { createSelector } from 'reselect';
import {
  CURRENT_YEAR,
  LAST_YEAR_AS_DATE,
  sortDateAsc,
  TODAY,
  MonthlyActual,
  MonthOverMonthReduxState,
  sumProp
} from '@qwealth/qdata';

import { when } from 'utils/function';

import { selectMonthlyActuals, State } from './monthOverMonthSelectors';

// hopefully, the number of month in a year doesn't change...
const MONTHS = range(0, 12);

const LAST_12_MONTH = set(subMonths(TODAY, 12), {
  date: 1,
  hours: 0,
  milliseconds: 0,
  minutes: 0,
  seconds: 0
});

export const selectSortedMonthlyActuals = createSelector<
  // @ts-ignore
  State,
  MonthOverMonthReduxState | {},
  Array<MonthlyActual>
  // @ts-ignore
>(selectMonthlyActuals, compose(sortDateAsc('date'), values));

// @ts-ignore
export const selectMonthlyActualsForCurrentYear = createSelector(
  selectSortedMonthlyActuals,
  filter(compose(isThisYear, parseISO, prop<any, any>('date')))
);

// @ts-ignore
export const selectMonthlyActualsForPreviousYear = createSelector(
  selectSortedMonthlyActuals,
  filter(compose(isSameYear(LAST_YEAR_AS_DATE), parseISO, prop<any, any>('date')))
);

export const selectMonthlyActualsForLast12Months = createSelector(
  selectSortedMonthlyActuals,
  compose<State, Array<MonthlyActual>, Array<MonthlyActual>>(
    take(12),
    // @ts-ignore
    filter(compose(partialRight(isAfter, [LAST_12_MONTH]), parseISO, prop<any, any>('date')))
  )
);

export const selectMonthlyActualsForLast2Years = createSelector(
  // @ts-ignore
  [selectMonthlyActualsForPreviousYear, selectMonthlyActualsForCurrentYear],
  (prevMonthActuals, currentMonthActuals) => {
    const prevActualsByMonth = reduce<MonthlyActual, Record<number, number>>(
      (acc, { date, value }) => {
        acc[getMonth(parseISO(date))] = value;
        return acc;
      },
      {},
      prevMonthActuals
    );

    const currentActualsByMonth = reduce<MonthlyActual, Record<number, number>>(
      (acc, { date, value }) => {
        acc[getMonth(parseISO(date))] = value;
        return acc;
      },
      {},
      currentMonthActuals
    );

    return MONTHS.map((month) => {
      const prev = prevActualsByMonth[month];
      const current = currentActualsByMonth[month];
      return {
        date: new Date(CURRENT_YEAR, month, 1).toISOString(),
        current,
        prev
      };
    });
  }
);

// @ts-ignore
export const selectAverageMonthlyActual = createSelector<State, number>(
  selectMonthlyActualsForLast12Months,
  (actuals) => {
    const total = sumProp('value')(actuals);
    return when(compose(not, isNil), (sum) => sum / actuals.length)(total);
  }
);
