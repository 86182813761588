import React from 'react';
import { Box } from '@qwealth/qcore';

export default function FormValue({ children, ...props }) {
  return (
    <Box display="flex" alignItems="center" fontFamily="TeX-Gyre-Adventor" fontSize="16px" fontWeight="600" {...props}>
      {children}
    </Box>
  );
}
