import React from 'react';
import { useSelector } from 'react-redux';

import { selectSpendings } from 'data/selectors/monthOverMonthSelectors';

import ItemizedGoals from './ItemizedGoals';

export default () => {
  // @ts-ignore
  const spendings = useSelector(selectSpendings);
  // @ts-ignore
  return <ItemizedGoals itemizedGoals={spendings} title="Spending" />;
};
