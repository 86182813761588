import React from 'react';
import { useSelector } from 'react-redux';
import { AccordionSection, Block, Box, toCurrency } from '@qwealth/qcore';

import FormValue from 'components/common/FormValue';
import { selectActualSpent, selectAnnualBudget } from 'data/selectors/monthOverMonthSelectors';
import { selectAverageMonthlyActual } from 'data/selectors/monthlyActualsSelectors';

const AnuualBudget = () => {
  // @ts-ignore
  const annualBudget = useSelector(selectAnnualBudget);
  // @ts-ignore
  const actualSpent = useSelector(selectActualSpent);
  // @ts-ignore
  const monthlyBudget = annualBudget / 12;
  const actualAverage = useSelector(selectAverageMonthlyActual);

  return (
    <AccordionSection
      backgroundColor="greenTransparent"
      collapsed
      color="green"
      fontSize="default"
      title="Annual Budget Goal"
      // @ts-ignore
      metadata={<Box textAlign="right">{toCurrency(annualBudget)}</Box>}
    >
      <Block>
        <Box display="flex" justifyContent="space-between">
          <Box>Annual Actual Spent</Box>
          {/* @ts-ignore */}
          <FormValue>{toCurrency(actualSpent)}</FormValue>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>Average Monthly</Box>
          <FormValue>{toCurrency(actualAverage)}</FormValue>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>Budget Monthly</Box>
          <FormValue>{toCurrency(monthlyBudget)}</FormValue>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>Actual to Budget</Box>
          <FormValue>
            {toCurrency(actualAverage ? monthlyBudget - actualAverage : undefined)}
          </FormValue>
        </Box>
      </Block>
    </AccordionSection>
  );
};

export default AnuualBudget;
