// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { toCurrency } from '@qwealth/qcore';

import Table, { StyledTableSummary } from 'components/common/Table';
import {
  selectCombinedAnnualizedDeductionList,
  selectCombinedTotalAnnualizedDeductions,
} from 'data/selectors/income-summary/annualizedDeductionsSelectors';

const COLUMNS = [
  {
    id: 'name',
    label: 'Deductions',
  },
  {
    className: 'text-right',
    format: (v?: number, index?: number) => (index === 0 ? <>{toCurrency(v)}</> : toCurrency(v)),
    id: 'amount',
    label: 'Amount',
    style: { whiteSpace: 'nowrap' },
    tdClassName: 'text-right',
  },
];

const AnnualizedDeductions = ({ profileId }) => {
  const data = useSelector(selectCombinedAnnualizedDeductionList);
  const total = useSelector(selectCombinedTotalAnnualizedDeductions);

  return (
    <Table columns={COLUMNS} data={data[profileId] || []} title="Deductions - Annualized">
      <StyledTableSummary footerLabel="Total Deductions">
        <td className="text-right">
          <b>{toCurrency(total[profileId])}</b>
        </td>
      </StyledTableSummary>
    </Table>
  );
};

export default AnnualizedDeductions;
