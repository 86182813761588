import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, styled, themeGet } from '@qwealth/qcore';
import SideNav from '../common/SideNav';
import Avatar from './Avatar';

const Overlay = styled(Box)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 998;
  top: 0;
  background-color: #00000050;
`;

const HeaderContainer = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #171021;
  color: ${themeGet('colors.white')};
`;

export default function Header() {
  const [isVisible, setVisible] = useState(false);
  const navigate = useNavigate();

  const toggleSideNav = (value) => {
    setVisible(value);
  };

  return (
    <HeaderContainer boxShadow="layout">
      <SideNav isVisible={isVisible} onToggle={toggleSideNav} />
      <Overlay display={isVisible ? 'block' : 'none'} onClick={() => setVisible(false)} />
      <Box mx="auto" px="default" pt="largest" pb="default">
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Box onClick={() => setVisible(!isVisible)}>
            <img alt="open" src="/images/menu-open.png" />
          </Box>
          <img
            alt="logo"
            src="/images/QConnect.png"
            width="40"
            onClick={() => navigate({ pathname: '/' })}
          />
          <Avatar />
        </Box>
      </Box>
    </HeaderContainer>
  );
}
