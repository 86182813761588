import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { RedSpan } from '../../../common/SplashStyling';

interface Props {
  name: string;
}

const Error: FC<Props> = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <RedSpan>{error}</RedSpan> : null
    }
  />
);

export default Error;
