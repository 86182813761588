// @ts-nocheck
import { compose, pathOr, propOr } from 'ramda';
import { Goal, ItemizedGoals, MonthlyActual, MonthOverMonthReduxState } from '@qwealth/qdata';

export type State = {
  monthOverMonth?: MonthOverMonthReduxState;
};

export const selectMonthOverMonth: (s: State) => MonthOverMonthReduxState | {} = propOr(
  {},
  'monthOverMonth',
);

export const selectActualSpent = compose<State, MonthOverMonthReduxState | {}, number>(
  propOr(0, 'actualSpent'),
  selectMonthOverMonth,
);

export const selectAnnualBudget = compose<State, MonthOverMonthReduxState | {}, number>(
  propOr(0, 'annualBudget'),
  selectMonthOverMonth,
);

export const selectMonthlyActuals = compose<
  State,
  MonthOverMonthReduxState | {},
  Record<string, MonthlyActual>
>(propOr({}, 'monthlyActuals'), selectMonthOverMonth);

export const selectSavings = compose<State, MonthOverMonthReduxState | {}, ItemizedGoals>(
  propOr({}, 'savings'),
  selectMonthOverMonth,
);

export const selectSavingsGoals = compose<State, MonthOverMonthReduxState | {}, Array<Goal>>(
  pathOr([], ['savings', 'goals']),
  selectMonthOverMonth,
);

export const selectSpendings = compose<State, MonthOverMonthReduxState | {}, ItemizedGoals>(
  propOr({}, 'spendings'),
  selectMonthOverMonth,
);

export const selectSpendingsGoals = compose<State, MonthOverMonthReduxState | {}, Array<Goal>>(
  pathOr([], ['spendings', 'goals']),
  selectMonthOverMonth,
);
