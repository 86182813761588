import { any, compose, isNil, not, partialRight, pickAll, prop, values } from 'ramda';

export interface CalcDecorator {
  <T, R = number | undefined>(callback: (input: T) => R): (input: T) => R;
}

export interface CalculateFn<T, R = number> {
  (input: T): R;
}

export const hasAllValues: (input: any) => boolean = compose(not, any(isNil), values);

/**
 * isProp
 */
interface IsProp {
  <V>(
    propName: keyof V,
    predicateFn: (a: number) => (b: number) => boolean,
    compareToValue: number,
  ): (input: V) => boolean;
}

export const isProp: IsProp = (propName, predicateFn, compareToValue) =>
  // @ts-ignore
  compose(partialRight(predicateFn, [compareToValue]), prop(propName));

/**
 * when
 */
interface When {
  <T, R = number>(condition: (conditionInput: T) => boolean, onTrue: CalculateFn<T, R>): (
    input: T,
  ) => R | undefined;
}
export const when: When = (condition, onTrue) => input =>
  condition(input) ? onTrue(input) : undefined;

/**
 * checkRequired
 */
interface CheckRequired {
  <T extends {}, R = number>(...requiredProps: string[]): (
    callback: CalculateFn<T, R>,
  ) => (input: T) => R | undefined;
}

export const checkRequired: CheckRequired = (...requiredProps) => callback =>
  when(compose(hasAllValues, pickAll(requiredProps)), callback);

/**
 * run
 */
interface Run {
  <T, R = number>(calcDefinitions: Array<CalculateFn<T, R>>): (input: T) => R | undefined;
}

export const run: Run = calcDefinitions => input => {
  let result;
  calcDefinitions.find(calc => {
    const value = calc(input as any);
    if (value !== undefined) {
      result = value;
      return true;
    }
    return false;
  });
  return result;
};
