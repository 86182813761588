import React from 'react';
import { isNil } from 'ramda';
import { AccordionSection, Block, Box, toCurrency } from '@qwealth/qcore';
import { sumProp } from '@qwealth/qdata';

import FormValue from 'components/common/FormValue';

const Summary = ({ transactionDetail }) => {
  const totalDeposits = sumProp('amount')(transactionDetail?.deposits || []);
  const totalItemizedSpendingGoals = sumProp('amount')(transactionDetail?.spendings || []);
  const totalSavings = sumProp('amount')(transactionDetail?.savings || []);

  const endingBalance = !isNil(transactionDetail?.monthSummary?.beginningBalance)
    ? (transactionDetail?.monthSummary?.beginningBalance || 0) +
      (totalDeposits || 0) -
      (transactionDetail?.monthSummary?.totalWithdrawal || 0)
    : undefined;

  const totalExpense =
    (transactionDetail?.monthSummary?.totalWithdrawal || 0) -
    (totalItemizedSpendingGoals || 0) -
    (totalSavings || 0);

  return (
    <AccordionSection
      backgroundColor="greenTransparent"
      collapsed
      color="green"
      fontSize="default"
      metadata={<Box textAlign="right">{toCurrency(totalExpense)}</Box>}
      title="Monthly Basic Expenses"
    >
      <Block>
        <Box display="flex" justifyContent="space-between">
          <Box>Beginning Balance</Box>
          <FormValue>{toCurrency(transactionDetail?.monthSummary?.beginningBalance)}</FormValue>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>Total Deposits</Box>
          <FormValue>{toCurrency(totalDeposits)}</FormValue>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>Total Withdrawal</Box>
          <FormValue>{toCurrency(transactionDetail?.monthSummary?.totalWithdrawal)}</FormValue>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>Itemized Spending Goals</Box>
          <FormValue>{toCurrency(totalItemizedSpendingGoals)}</FormValue>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>Less Balance Sheet</Box>
          <FormValue>{toCurrency(totalSavings)}</FormValue>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>Ending Balance</Box>
          <FormValue>{toCurrency(endingBalance)}</FormValue>
        </Box>
      </Block>
    </AccordionSection>
  );
};

export default Summary;
