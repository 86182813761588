import React from 'react';
import { AccordionSection, Block, Box, toCurrency } from '@qwealth/qcore';

import FormValue from 'components/common/FormValue';
import { ItemizedGoals as ItemizedGoalsType } from '@qwealth/qdata';

type Props = {
  itemizedGoals?: ItemizedGoalsType;
  title: string;
};

const ItemizedGoals: React.FC<Props> = ({ itemizedGoals, title }) => {
  const goals = itemizedGoals?.goals || [];

  return (
    <AccordionSection
      backgroundColor="gray.4"
      collapsed={false}
      color="dark"
      fontSize="default"
      title={`Itemized ${title} Goals`}
    >
      <Box display="grid" rowGap="default">
        {goals.map((goal) => (
          <Block key={goal.id}>
            <Box textStyle="formLabel">{goal.comment}</Box>
            <Box display="flex" justifyContent="space-between">
              <Box>Target</Box>
              <FormValue>{toCurrency(goal.target)}</FormValue>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box>Actual</Box>
              <FormValue>{toCurrency(goal.actual)}</FormValue>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box>Actual-Target</Box>
              <FormValue
                color={{
                  color:
                    goal.target && goal.actual && goal.target - goal.actual > 0 ? 'green' : 'red',
                }}
              >
                {toCurrency(goal.target && goal.actual && goal.target - goal.actual)}
              </FormValue>
            </Box>
          </Block>
        ))}
      </Box>
    </AccordionSection>
  );
};

export default ItemizedGoals;
