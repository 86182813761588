// @ts-nocheck
import { compose, propOr } from 'ramda';
import { DataSourceReduxState } from '../reducers/dataSource';
import { DataSourceConstants, TaxBracket } from '../data-layer/dataSource';

type State = {
  dataSource?: DataSourceReduxState;
};

export const selectDataSourceRoot: (s: State) => DataSourceReduxState | {} = propOr(
  {},
  'dataSource',
);

export const selectConstants = compose<State, DataSourceReduxState | {}, DataSourceConstants>(
  propOr({}, 'constants'),
  selectDataSourceRoot,
);

export const selectIncomeAndTaxAdjustmentTypes = compose<
  State,
  DataSourceReduxState | {},
  Array<string>
>(propOr([], 'incomeAndTaxAdjustmentTypes'), selectDataSourceRoot);

export const selectIncomeSourceTypes = compose<State, DataSourceReduxState | {}, Array<string>>(
  propOr([], 'incomeSourceTypes'),
  selectDataSourceRoot,
);

export const selectProvinces = compose<State, DataSourceReduxState | {}, Array<string>>(
  propOr([], 'provinces'),
  selectDataSourceRoot,
);

export const selectFederalTaxBrackets = compose<
  State,
  DataSourceReduxState | {},
  Array<TaxBracket>
>(propOr({}, 'federalTaxBrackets'), selectDataSourceRoot);

export const selectProvincialTaxBrackets = compose<
  State,
  DataSourceReduxState | {},
  Record<string, Array<TaxBracket>>
>(propOr({}, 'provincialTaxBrackets'), selectDataSourceRoot);
