/* eslint-disable */
// @ts-nocheck
import { any, compose, find, keys, mapObjIndexed, propEq, propOr, reject } from 'ramda';
import { createSelector } from 'reselect';
import {
  calculateCppQppSelfEmployed,
  calculateRRSP,
  DeductionAndAdjustment,
  RRSP,
  IncomeSummaryReduxState
} from '@qwealth/qdata';

import { PersonWithRRSP } from '../../data-layer/person';
import { injectCombinedData, injectCombinedSumValue, sumAmount } from '../utils';

import { selectCurrentHouseholdProfiles } from '../householdSelectors';
import { selectIncomeSummaryRoot, State } from './incomeSummarySelectors';
import {
  selectEarnedIncomeByProfileId,
  selectHasCppQppIncomeSourcesByProfileId
} from './incomeSourceSelectors';

const isTypeEqSpousalRRSP = propEq('type', 'Spousal RRSP');
const hasSpousalRRSP = any(isTypeEqSpousalRRSP);
const findSpousalRRSP = find(isTypeEqSpousalRRSP);
const getRRSPs = (primaryRRSPs: Array<RRSP> = [], secondaryRRSPs: Array<RRSP> = []): Array<RRSP> =>
  hasSpousalRRSP(secondaryRRSPs)
    ? [...reject(isTypeEqSpousalRRSP, primaryRRSPs), findSpousalRRSP(secondaryRRSPs) as RRSP]
    : reject(isTypeEqSpousalRRSP, primaryRRSPs);

export const selectRrspAdjustmentsByProfileId = createSelector(
  [selectCurrentHouseholdProfiles, selectEarnedIncomeByProfileId],
  (profiles: Record<string, PersonWithRRSP>, earnedIncomes: Record<string, number | undefined>) => {
    const [primaryKey, secondaryKey] = keys(profiles);
    if (!primaryKey && !secondaryKey) {
      return {};
    }

    // const [primary, secondary] = values(profiles);
    return {
      [primaryKey]: calculateRRSP({
        // @ts-ignore
        earnedIncome: earnedIncomes[primaryKey],
        rrsps: getRRSPs(profiles[primaryKey]?.rrsps || [], profiles[secondaryKey]?.rrsps || [])
      }),
      [secondaryKey]: calculateRRSP({
        // @ts-ignore
        earnedIncome: earnedIncomes[secondaryKey],
        rrsps: getRRSPs(profiles[secondaryKey]?.rrsps || [], profiles[primaryKey]?.rrsps || [])
      })
    };
  }
);

export const selectCppQppSelfEmployedByProfileId = createSelector(
  [
    selectCurrentHouseholdProfiles,
    selectEarnedIncomeByProfileId,
    selectHasCppQppIncomeSourcesByProfileId
  ],
  (profiles, earnedIncomes, hasCppQppIncomeSources) =>
    mapObjIndexed(
      ({ employmentStatus, provinceOrState: province }, key) =>
        calculateCppQppSelfEmployed({
          // @ts-ignore
          earnedIncome: earnedIncomes[key],
          employmentStatus,
          hasCppQppIncome: hasCppQppIncomeSources[key],
          province
        }),
      profiles
    )
);

export const selectDeductionsAndAdjustmentsByProfileId = compose<
  State,
  IncomeSummaryReduxState | {},
  Record<string, Array<DeductionAndAdjustment>>
>(propOr({}, 'deductionsAndAdjustments'), selectIncomeSummaryRoot);

export const selectDeductionsAndAdjustmentsWithRRSPAndCppQppByProfileId = createSelector(
  [
    selectCurrentHouseholdProfiles,
    selectDeductionsAndAdjustmentsByProfileId,
    selectRrspAdjustmentsByProfileId,
    selectCppQppSelfEmployedByProfileId
  ],
  (profiles, deductionsAndAdjustmentsByProfileId, rrspByProfileId, cppQppSelfEmployedByProfileId) =>
    mapObjIndexed(
      (profile, key) => [
        { name: 'RRSP Deduction', amount: rrspByProfileId[key] },
        {
          name: 'CPP/QPP (on Self Employment Portion)',
          amount: cppQppSelfEmployedByProfileId[key]
        },
        ...(deductionsAndAdjustmentsByProfileId[key] || [])
      ],
      profiles
    )
);

export const selectCombinedDeductionsAndAdjustmentsWithRRSPAndCppQppByProfileId = createSelector(
  selectDeductionsAndAdjustmentsWithRRSPAndCppQppByProfileId,
  injectCombinedData('amount')
);

// @ts-ignore
export const selectSumDeductionsAndAdjustmentsByProfileId = createSelector(
  // @ts-ignore
  selectDeductionsAndAdjustmentsWithRRSPAndCppQppByProfileId,
  sumAmount()
);

export const selectCombinedSumDeductionsAndAdjustmentsByProfileId = createSelector(
  selectSumDeductionsAndAdjustmentsByProfileId,
  injectCombinedSumValue
);
