import { selectSavings } from 'data/selectors/monthOverMonthSelectors';
import React from 'react';
import { useSelector } from 'react-redux';

import ItemizedGoals from './ItemizedGoals';

export default () => {
  // @ts-ignore
  const savings = useSelector(selectSavings);
  // @ts-ignore
  return <ItemizedGoals itemizedGoals={savings} title="Saving" />;
};
