import React from 'react';
import { useSelector } from 'react-redux';
import { toCurrency } from '@qwealth/qcore';

import Table, { StyledTableSummary } from 'components/common/Table';
import {
  selectCombinedDisposableIncomeItems,
  selectCombinedTotalDisposableIncomesByProfileId,
} from 'data/selectors/income-summary/disposableIncomeSelectors';

const COLUMNS = [
  {
    id: 'name',
    label: 'Disposable Income',
  },
  {
    className: 'text-right',
    format: (v?: number) => toCurrency(v),
    id: 'amount',
    label: 'Amount',
    style: { whiteSpace: 'nowrap' },
    tdClassName: 'text-right',
  },
];

const DisposableIncome = ({ profileId }) => {
  const data = useSelector(selectCombinedDisposableIncomeItems);
  const total = useSelector(selectCombinedTotalDisposableIncomesByProfileId);

  return (
    <Table columns={COLUMNS} data={data[profileId] || []} title="Disposable Income Calculation">
      <StyledTableSummary footerLabel="Total Disposable Income">
        <td className="text-right">
          <b>{toCurrency(total[profileId])}</b>
        </td>
      </StyledTableSummary>
    </Table>
  );
};

export default DisposableIncome;
