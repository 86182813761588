/* eslint-disable */
// @ts-nocheck
import { compose, isNil, mapObjIndexed, mergeDeepWith, propOr, subtract, reject, sum } from 'ramda';
import { createSelector } from 'reselect';
import {
  calculateQpip,
  calculateCppQppEmployee,
  calculateCppQppSelfEmployed,
  calculateEIPremium,
  calculateFederalTax,
  calculateProvincialTax,
  calculateTotalFederalCredits,
  FederalTaxCredits,
  IncomeSummaryReduxState,
  IPerson,
  TotalFederalCreditsInput
} from '@qwealth/qdata';
import { when } from 'utils/function';
import { selectCurrentHouseholdProfiles } from '../householdSelectors';
import { injectCombinedData, injectCombinedSumValue, isListNotEmpty } from '../utils';

import {
  selectCombinedSumDeductionsAndAdjustmentsByProfileId,
  selectSumDeductionsAndAdjustmentsByProfileId,
} from './deductionsAndAdjustmentsSelectors';
import {
  selectEarnedIncomeByProfileId,
  selectCombinedSumTaxableAmountsByProfileId,
  selectHasCppQppIncomeSourcesByProfileId,
  selectSumTaxableAmountsByProfileId,
  selectTaxableEligibleDividendsByProfileId,
  selectTaxableIneligibleDividendsByProfileId,
} from './incomeSourceSelectors';
import { State, selectIncomeSummaryRoot } from './incomeSummarySelectors';
import { selectProvincialTaxBrackets, selectFederalTaxBrackets } from '../dataSourceSelectors';

export const selectFederalTaxCreditsByProfileId = compose<
  State,
  IncomeSummaryReduxState | {},
  Record<string, FederalTaxCredits>
>(propOr({}, 'federalTaxCredits'), selectIncomeSummaryRoot);

export const selectTaxableIncomesByProfileId = createSelector(
  [selectSumTaxableAmountsByProfileId, selectSumDeductionsAndAdjustmentsByProfileId],
  mergeDeepWith((taxableAmount, deductions = 0) => {
    if (isNil(taxableAmount)) {
      return undefined;
    }
    return subtract(taxableAmount, deductions);
  }),
);

export const selectCombinedTaxableIncomesByProfileId = createSelector(
  [
    selectCombinedSumTaxableAmountsByProfileId,
    selectCombinedSumDeductionsAndAdjustmentsByProfileId,
  ],
  mergeDeepWith((taxableAmount, deductions = 0) => {
    if (isNil(taxableAmount)) {
      return undefined;
    }
    return subtract(taxableAmount, deductions);
  }),
);

export const selectFederalNetOfCreditsByProfileId = createSelector(
  [
    selectCurrentHouseholdProfiles,
    selectEarnedIncomeByProfileId,
    selectFederalTaxBrackets,
    selectFederalTaxCreditsByProfileId,
    selectHasCppQppIncomeSourcesByProfileId,
    selectTaxableEligibleDividendsByProfileId,
    selectTaxableIncomesByProfileId,
    selectTaxableIneligibleDividendsByProfileId,
  ],
  (
    profiles,
    earnedIncomes,
    federalTaxBrackets,
    federalTaxCredits,
    hasCppQppIncomeSources,
    taxableEligibleDividends,
    taxableIncomes,
    taxableIneligibleDividends,
  ) =>
    mapObjIndexed<IPerson, number | undefined, string>(({ employmentStatus, provinceOrState: province }, key) => {
      const taxableIncome = taxableIncomes[key];
      const { ageAmount, eligibleDependent } = federalTaxCredits[key] || {};

      const totalFederalCreditsInput: TotalFederalCreditsInput = {
        ageAmount,
        eligibleDependent,
        earnedIncome: earnedIncomes[key],
        employmentStatus,
        hasCppQppIncome: hasCppQppIncomeSources[key],
        province,
        taxableEligibleDividends: taxableEligibleDividends[key],
        taxableIncome,
        taxableIneligibleDividends: taxableIneligibleDividends[key],
      };

      const federalTax = calculateFederalTax({ federalTaxBrackets, taxableIncome });
      // @ts-ignore
      const credits = calculateTotalFederalCredits(totalFederalCreditsInput) || 0;

      // only check for federalTax, because that's the start of the calc
      return !isNil(federalTax) ? federalTax - credits : undefined;
    })(profiles),
);

export const selectProvincialTaxByProfileId = createSelector(
  [selectCurrentHouseholdProfiles, selectTaxableIncomesByProfileId, selectProvincialTaxBrackets],
  (profiles, taxableIncomes, provincialTaxBrackets) =>
    mapObjIndexed<IPerson, number | undefined, string>(({ provinceOrState: province }, key) =>
      calculateProvincialTax({
        province,
        provincialTaxBrackets,
        taxableIncome: taxableIncomes[key],
      }),
    )(profiles),
);

export const selectCppQppEmployeeByProfileId = createSelector(
  [
    selectCurrentHouseholdProfiles,
    selectEarnedIncomeByProfileId,
    selectHasCppQppIncomeSourcesByProfileId,
  ],
  (profiles, earnedIncomes, hasCppQppIncomeSources) =>
    mapObjIndexed<IPerson, number | undefined, string>(({ employmentStatus, provinceOrState: province }, key) =>
      calculateCppQppEmployee({
        earnedIncome: earnedIncomes[key],
        employmentStatus,
        hasCppQppIncome: hasCppQppIncomeSources[key],
        province,
      }),
    )(profiles),
);

export const selectCppQppEmployerByProfileId = createSelector(
  [
    selectCurrentHouseholdProfiles,
    selectEarnedIncomeByProfileId,
    selectHasCppQppIncomeSourcesByProfileId,
  ],
  (profiles, earnedIncomes, hasCppQppIncomeSources) =>
    mapObjIndexed<IPerson, number | undefined, string>(({ employmentStatus, provinceOrState: province }, key) =>
      calculateCppQppSelfEmployed({
        earnedIncome: earnedIncomes[key],
        employmentStatus,
        hasCppQppIncome: hasCppQppIncomeSources[key],
        province,
      }),
    )(profiles),
);

export const selectEIPremiumsByProfileId = createSelector(
  [selectCurrentHouseholdProfiles, selectEarnedIncomeByProfileId],
  (profiles, earnedIncomes) =>
    mapObjIndexed<IPerson, number | undefined, string>(({ employmentStatus, provinceOrState: province }, key) =>
      calculateEIPremium({
        earnedIncome: earnedIncomes[key],
        employmentStatus,
        province,
      }),
    )(profiles),
);

export const selectQpipsByProfileId = createSelector(
  [selectCurrentHouseholdProfiles, selectEarnedIncomeByProfileId],
  (profiles, earnedIncomeByProfileId) =>
    mapObjIndexed<IPerson, number | undefined, string>(({ provinceOrState: province }, key) => {
      const earnedIncome = earnedIncomeByProfileId[key];
      return calculateQpip({
        earnedIncome,
        province,
      });
    })(profiles),
);

export const selectTotalAnnualizedDeductions = createSelector(
  [
    selectFederalNetOfCreditsByProfileId,
    selectProvincialTaxByProfileId,
    selectCppQppEmployeeByProfileId,
    selectCppQppEmployerByProfileId,
    selectEIPremiumsByProfileId,
    selectQpipsByProfileId,
  ],
  (federalNetOfCredits, provincialTaxes, cppQppEmployees, cppQppEmployers, eiPremiums, qpips) =>
    mapObjIndexed((federalNetOfCredit, key) => {
      const listToSum = [
        federalNetOfCredit,
        provincialTaxes[key],
        cppQppEmployees[key],
        cppQppEmployers[key],
        eiPremiums[key],
        qpips[key],
      ] as Array<number>;
      // @ts-ignore
      return compose(when(isListNotEmpty, sum), reject(isNil))(listToSum);
    }, federalNetOfCredits),
);

export const selectCombinedTotalAnnualizedDeductions = createSelector(
  selectTotalAnnualizedDeductions,
  injectCombinedSumValue,
);

export const selectAnnualizedDeductionList = createSelector(
  [
    selectFederalNetOfCreditsByProfileId,
    selectProvincialTaxByProfileId,
    selectCppQppEmployeeByProfileId,
    selectCppQppEmployerByProfileId,
    selectEIPremiumsByProfileId,
    selectQpipsByProfileId,
  ],
  (federalNetOfCredits, provincialTaxes, cppQppEmployees, cppQppEmployers, eiPremiums, qpips) =>
    mapObjIndexed(
      (federalNetOfCredit, key) => [
        { name: 'Federal - Net of Credits', amount: federalNetOfCredit },
        { name: 'Provincial Tax', amount: provincialTaxes[key] },
        { name: 'CPP/QPP Employee', amount: cppQppEmployees[key] },
        { name: 'CPP/QPP Employer (Self Employed Only)', amount: cppQppEmployers[key] },
        { name: 'EI', amount: eiPremiums[key] },
        { name: 'QPIP', amount: qpips[key] },
      ],
      federalNetOfCredits,
    ),
);

export const selectCombinedAnnualizedDeductionList = createSelector(
  selectAnnualizedDeductionList,
  injectCombinedData('amount'),
);
