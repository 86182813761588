import React from 'react';
import { AccordionSection, Block, Box, toCurrency } from '@qwealth/qcore';

import FormValue from 'components/common/FormValue';
import {Transaction} from "@qwealth/qdata";

type Props = {
  spendings?: Array<Transaction>;
};

const Spendings: React.FC<Props> = ({ spendings = [] }) => (
  <AccordionSection
    backgroundColor="gray.4"
    collapsed={false}
    color="dark"
    fontSize="default"
    title="Spendings"
  >
    <Box display="grid" rowGap="default">
      {spendings.map((spending) => (
        <Block key={spending.guid}>
          <Box textStyle="formLabel">{spending.memo}</Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Source</Box>
            <FormValue>{spending.source}</FormValue>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Type</Box>
            <FormValue>{spending.type}</FormValue>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Amount</Box>
            <FormValue>{toCurrency(spending.amount)}</FormValue>
          </Box>
        </Block>
      ))}
    </Box>
  </AccordionSection>
);

export default Spendings;
