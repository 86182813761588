import React from 'react';
import { AccordionSection, Block, Box, toCurrency } from '@qwealth/qcore';

import FormValue from 'components/common/FormValue';
import {Transaction} from "@qwealth/qdata";

type Props = {
  savings?: Array<Transaction>;
};

const Savings: React.FC<Props> = ({ savings = [] }) => (
  <AccordionSection
    backgroundColor="gray.4"
    collapsed={false}
    color="dark"
    fontSize="default"
    title="Savings"
  >
    <Box display="grid" rowGap="default">
      {savings.map((saving) => (
        <Block key={saving.guid}>
          <Box textStyle="formLabel">{saving.source}</Box>
          <Box display="flex" justifyContent="space-between">
            <Box>Amount</Box>
            <FormValue>{toCurrency(saving.amount)}</FormValue>
          </Box>
        </Block>
      ))}
    </Box>
  </AccordionSection>
);

export default Savings;
