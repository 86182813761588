/* eslint-disable */
// @ts-nocheck
import { mapObjIndexed, isNil } from 'ramda';
import { createSelector } from 'reselect';
import { calculateOASClawback } from '@qwealth/qdata';

import { injectCombinedData, injectCombinedSumValue } from '../utils';
import {
  selectTotalAnnualizedDeductions,
  selectTaxableIncomesByProfileId,
} from './annualizedDeductionsSelectors';
import {
  selectTaxableAmountOldAgeSecuritiesByProfileId,
  selectSumTaxableAmountsByProfileId,
} from './incomeSourceSelectors';

export const selectOldAgeSecurityIncomesByProfileId = createSelector(
  [selectTaxableAmountOldAgeSecuritiesByProfileId, selectTaxableIncomesByProfileId],
  (taxableAmountOASs, totalTaxableIncomes) =>
    mapObjIndexed(
      (taxableIncome: number, key) =>
        calculateOASClawback({
          oldAgeSecurityIncomes: taxableAmountOASs[key],
          taxableIncome,
        }),
      totalTaxableIncomes,
    ),
);

export const selectTotalDisposableIncomesByProfileId = createSelector(
  [
    selectOldAgeSecurityIncomesByProfileId,
    selectSumTaxableAmountsByProfileId,
    selectTaxableIncomesByProfileId,
    selectTotalAnnualizedDeductions,
  ],
  (
    oldAgeSecurities,
    taxableIncomeBeforeAdjustments,
    totalTaxableIncomes,
    totalAnnualizedDeductions,
  ) =>
    mapObjIndexed((taxableIncome: number, key) => {
      if (isNil(taxableIncome)) {
        return undefined;
      }

      const taxableIncomeBeforeAdjustment = taxableIncomeBeforeAdjustments[key] || 0;
      const deduction = totalAnnualizedDeductions[key] || 0;
      const oas = oldAgeSecurities[key] || 0;
      return taxableIncomeBeforeAdjustment - deduction - oas;
    }, totalTaxableIncomes),
);

export const selectCombinedTotalDisposableIncomesByProfileId = createSelector(
  selectTotalDisposableIncomesByProfileId,
  injectCombinedSumValue,
);

export const selectDisposableIncomeItems = createSelector(
  [
    selectOldAgeSecurityIncomesByProfileId,
    selectSumTaxableAmountsByProfileId,
    selectTotalAnnualizedDeductions,
  ],
  (oldAgeSecurities, taxableIncomeBeforeAdjustments, totalAnnualizedDeductions) =>
    mapObjIndexed(
      (taxableIncomeBeforeAdjustment, key) => [
        { name: 'Gross Income (1500)', amount: taxableIncomeBeforeAdjustment },
        { name: 'Less Deductions', amount: totalAnnualizedDeductions[key] },
        { name: 'Less OAS Clawback - if Applicable', amount: oldAgeSecurities[key] },
      ],
      taxableIncomeBeforeAdjustments,
    ),
);

export const selectCombinedDisposableIncomeItems = createSelector(
  selectDisposableIncomeItems,
  injectCombinedData('amount'),
);
