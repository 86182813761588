import React, { useMemo, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Box, Card, styled, useDispatchOnMount } from '@qwealth/qcore';
import {
  getIncomeSummary,
  getPersons,
  IPerson,
  selectPrimary,
  selectSecondary
} from '@qwealth/qdata';

import DeductionsAndAdjustments from './DeductionsAndAdjustments';
import IncomeSources from './IncomeSources';
import AnnualizedDeductions from './AnnualizedDeductions';
import DisposableIncome from './DisposableIncome';
import { selectAuthenticatedUsersHousehold } from 'data/selectors/userSelectors';

const StyledSelectInput = styled.div`
  display: flex;
  align-items: center;

  select {
    padding-right: 2rem;
    font-size: 20px;
    font-weight: 600;
    border: none;
    background: transparent;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  option {
    font-size: 14px;
    font-weight: initial;
  }

  &::after {
    content: '';
    height: 10px;
    width: 10px;
    margin-top: 0.4rem;
    margin-left: -1.25rem;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.16658 0.64168C9.01045 0.486471 8.79924 0.399353 8.57908 0.399353C8.35893 0.399353 8.14772 0.486471 7.99158 0.64168L4.99992 3.59168L2.04992 0.64168C1.89378 0.486471 1.68257 0.399353 1.46242 0.399353C1.24226 0.399353 1.03105 0.486471 0.874917 0.64168C0.79681 0.719149 0.734814 0.811317 0.692507 0.912866C0.6502 1.01442 0.628418 1.12334 0.628418 1.23335C0.628418 1.34336 0.6502 1.45228 0.692507 1.55383C0.734814 1.65538 0.79681 1.74754 0.874917 1.82501L4.40825 5.35835C4.48572 5.43645 4.57789 5.49845 4.67944 5.54076C4.78098 5.58306 4.88991 5.60485 4.99992 5.60485C5.10993 5.60485 5.21885 5.58306 5.3204 5.54076C5.42195 5.49845 5.51411 5.43645 5.59158 5.35835L9.16658 1.82501C9.24469 1.74754 9.30668 1.65538 9.34899 1.55383C9.3913 1.45228 9.41308 1.34336 9.41308 1.23335C9.41308 1.12334 9.3913 1.01442 9.34899 0.912866C9.30668 0.811317 9.24469 0.719149 9.16658 0.64168Z' fill='%23272429'/%3E%3C/svg%3E%0A");
  }
`;

type SelectOption = {
  id: string;
  name: string;
}

const IncomeSummary = () => {
  const householdId = useSelector(selectAuthenticatedUsersHousehold);
  useDispatchOnMount(getPersons, householdId);
  useDispatchOnMount(getIncomeSummary, householdId);

  const primary: IPerson = useSelector(selectPrimary, shallowEqual);
  const secondary: IPerson = useSelector(selectSecondary, shallowEqual);

  const [selectedProfile, setSelectedProfile] = useState(
    primary && !secondary ? primary.QID as string : 'combined',
  );

  const profileList = useMemo(() => {
    let list: Array<SelectOption> = [];
    if (primary && secondary) {
      list = [
        { id: 'combined', name: 'Combined' },
        { id: primary.QID as string, name: `${primary.firstName} ${primary.lastName}` },
        { id: secondary.QID as string, name: `${secondary.firstName} ${secondary.lastName}` },
      ];
    } else if (primary && !secondary) {
      list = [{ id: primary.QID as string, name: `${primary.firstName} ${primary.lastName}` }];
    }
    return list;
  }, [primary, secondary])

  return (
    <Card
      // @ts-ignore
      className="content-container"
    >
      <Box p="default">
        <StyledSelectInput>
          <select
            onChange={(event) => setSelectedProfile(event.target.value)}
            value={selectedProfile}
          >
            {profileList.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
        </StyledSelectInput>
      </Box>

      <DisposableIncome profileId={selectedProfile} />
      <IncomeSources profileId={selectedProfile} />
      <DeductionsAndAdjustments profileId={selectedProfile} />
      <AnnualizedDeductions profileId={selectedProfile} />
    </Card>
  );
};

export default IncomeSummary;
