/* eslint-disable */
import { compose, isEmpty, isNil, mapObjIndexed, not, reject, sum, values } from 'ramda';
import { sumProp, when } from '@qwealth/qdata';

export const isListNotEmpty = compose(not, isEmpty);

/**
 * Sum the list of objects with 'amount' properties in the dictionary.
 *
 * input - {
 *   a: [{ amount: 1 }, { amount: 2 }, { amount: 3}],
 *   b: [{ amount: 3 }, { amount: 2 }],
 *   c: []
 * }
 *
 * output - {
 *   a: 6,
 *   b: 5,
 *   c: undefined
 * }
 */
export const sumAmount = (propName = 'amount') => mapObjIndexed(sumProp(propName));

export const sumAmountWithoutProp = mapObjIndexed(
  // @ts-ignore
  when(compose(isListNotEmpty, reject(isNil)), compose(sum, reject(isNil))),
);

const add = (value1: number | undefined, value2: number | undefined): number | undefined => {
  if (value1 === undefined && value2 === undefined) {
    return undefined;
  }
  return (value1 || 0) + (value2 || 0);
};

export const injectCombinedData = (...props) => listByProfileId => {
  const [primary = [], secondary = []] = values(listByProfileId);
  const usedIndices: Array<number> = [];
  const combined = secondary.reduce(
    (acc, item) => {
      const index = primary.findIndex(
        ({ name }, idx) => name === item.name && !usedIndices.includes(idx),
      );
      if (index > -1) {
        acc[index] = props.reduce((obj, prop) => {
          obj[prop] = add(acc[index][prop], item[prop]);
          return obj;
        }, { ...acc[index] });
        usedIndices.push(index);
      } else {
        usedIndices.push(acc.length);
        acc.push(item);
      }
      return acc;
    },
    [...primary],
  );

  return {
    ...listByProfileId,
    combined,
  };
};

export const injectCombinedSumValue = valueByProfileId => ({
  ...valueByProfileId,
  // @ts-ignore
  combined: compose(sum, values)(valueByProfileId),
})
