import React, { Dispatch, FC, ReactElement, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@qwealth/qcore';
import { selectAuthUserHouseholdId, selectUser } from '@qwealth/qdata';
import { DemographicForm, EmploymentInfoConfirmation, saveEmployment, useEmploymentInfo } from '@qwealth/kyc';
import { useAppDispatch } from "data/store";
import { UpdateButton } from '../common';
import EditEmployment from './EditEmployment';

interface Props {
  QID: string;
  demographicInfo: DemographicForm;
  setDemographicInfo: Dispatch<SetStateAction<DemographicForm>>;
}

const EmploymentInformation: FC<Props> = ({ QID, demographicInfo, setDemographicInfo }): ReactElement => {
  const { email } = useSelector(selectUser);
  const householdQID = useSelector(selectAuthUserHouseholdId);

  const dispatch = useAppDispatch();
  const { employmentInfo, setEmploymentInfo } = useEmploymentInfo(QID, demographicInfo);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const toggleModal = () => setShowEditModal(!showEditModal);
  return (
    <Box display="flex" flexDirection="column">
      <EmploymentInfoConfirmation employmentInfo={employmentInfo} />
      <EditEmployment
        updateFormValues={async (values) => {
          await dispatch(saveEmployment(QID, values, householdQID, email));
          const { employmentStatus, school } = values;
          setDemographicInfo((prevState) => ({ ...prevState, employmentStatus, school }));
          setEmploymentInfo(values);
        }}
        initialValues={employmentInfo}
        shouldShowModal={showEditModal}
        onClose={toggleModal}
      />
      <UpdateButton color="green" onClick={toggleModal}>
        Update
      </UpdateButton>
    </Box>
  );
};

export default EmploymentInformation;
