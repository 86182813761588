// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Box } from '@qwealth/qcore';
import {
  parseYearAndMonth,
  TransactionDetail
} from "@qwealth/qdata";

import { fetchTransactionDetail } from 'data/data-layer/transactions';

import Deposits from './Deposits';
import Savings from './Savings';
import Spendings from './Spendings';
import Summary from './Summary';
import { selectAuthenticatedUsersHousehold } from '../../../data/selectors/userSelectors';

const MonthDetail = () => {
  const HOUSEHOLD_ID = useSelector(selectAuthenticatedUsersHousehold, shallowEqual);
  const { yearAndMonth } = useParams<{ yearAndMonth: string }>();
  const [transactionDetail, setTransactionDetail] = useState<TransactionDetail | undefined>();
  useEffect(() => {
    if (yearAndMonth) {
      fetchTransactionDetail(HOUSEHOLD_ID, yearAndMonth).then(setTransactionDetail);
    }
  }, [setTransactionDetail, yearAndMonth]);

  return (
    <Box p="default">
      <Box textStyle="sectionTitle" mb="default" ml="large">
        {format(parseYearAndMonth(yearAndMonth), 'MMMM yyyy')}
      </Box>
      <Summary transactionDetail={transactionDetail} />
      <Savings savings={transactionDetail?.savings} />
      <Deposits deposits={transactionDetail?.deposits} />
      <Spendings spendings={transactionDetail?.spendings} />
    </Box>
  );
};

export default MonthDetail;
