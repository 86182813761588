import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { path } from 'ramda';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Routes, useMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Authenticator, ThemeProvider as AmplifyThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Loader, useDispatchOnMount } from '@qwealth/qcore';
import { QContext } from '@qwealth/qdata';
import { QBiasSurvey } from '@qwealth/qbias';

import { getAuthenticatedUser, getUserHouseholdId } from 'data/actions/usersActions';
import { configureAxios } from './services/axiosService';
import './App.css';
import { CognitoComponents, formFields, theme } from './components/CognitoLogin';
import Layout from "./components/Layout";
import { RootState } from "./data/store";
import ForgotPassword from "./components/ForgotPassword";
import Splash from "./components/Attestation/Splash";
import DemographicReview from "./components/Attestation/DemographicReview";
import RtqReview from "./components/Attestation/RTQReview";
import Confirmation from "./components/Attestation/Confirmation";
import IncomeSummary from 'components/QCash/incomesummary';
import MonthDetail from 'components/QCash/monthdetail';
import MonthOverMonth from 'components/QCash/monthovermonth';

const RTSurvey = lazy(() => import('./components/RTSurvey'));
const KycContainer = lazy(() => import('./components/KycContainer'));
const Account = lazy(() => import('./components/Account'));
const Baseline = lazy(() => import('./components/QLife/BaselinePlan'));
const CalendlyBooking = lazy(() => import('./components/CalendlyBooking'));
const Cashflow = lazy(() => import('./components/QCash'));
const Communications = lazy(() => import('components/Communications'));
const DataLoader = lazy(() => import('./components/DataLoader'));
const Functions = lazy(() => import('./components/QLife/Functions'));
const FlowReport = lazy(() => import('./components/QLife/FlowReport'));
const Home = lazy(() => import('./components/Home'));
const NetWorth = lazy(() => import('./components/NetWorth'));
const Profile = lazy(() => import('./components/Profile'));
const ProjectedNetWorthReport = lazy(() => import('./components/QLife/ProjectedNetWorth'));
const QScore = lazy(() => import('./components/QLife/QScore'));
const Team = lazy(() => import('./components/Team'));
const Values = lazy(() => import('./components/QLife/Values'));
const Vault = lazy(() => import('./components/Vault'));

const App: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user, shallowEqual);

  const [isAxiosInitialized, setAxiosInitialized] = useState(false);
  const [username, setUsername] = useState('');

  const listener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        setToken();
        cancelHubListener();
        break;
    }
  };
  const cancelHubListener = Hub.listen('auth', listener);

  const setToken = useCallback(async () => {
    Promise.all([Auth.currentAuthenticatedUser(), Auth.currentSession()])
      .then(([currentUser, currentSession]) => currentUser.refreshSession(currentSession.getRefreshToken(), (err, newSession) => {
        if (err) {
          console.error('failed to refresh cognito token', err);
        } else {
          console.log('Cognito connected and refreshed!', newSession);
          const cognitoToken: string | undefined = path(['idToken', 'jwtToken'], newSession);
          const email = currentUser.getUsername();

          if (cognitoToken && email) {
            setUsername(email);

            Sentry.setUser({ email });

            QContext.setIdToken(cognitoToken);
            configureAxios().then(() => setAxiosInitialized(true));
          } else {
            setAxiosInitialized(false);
          }
        }
      }))
      .catch(console.error);
  }, [setUsername, setAxiosInitialized]);

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState) => {
      setToken();

      if (nextAuthState === AuthState.SignedIn) {
        setTimeout(async () => {
          setAxiosInitialized(false);
          QContext.setIdToken('');
          await Auth.signOut({ global: true });
        }, 3600000);
      }
    });
  }, [setToken]);

  useEffect(() => {
    setToken();
    setInterval(setToken, 1000 * 60);
  }, [setToken]);

  // get authenticated user's household id
  useDispatchOnMount(getAuthenticatedUser, isAxiosInitialized ? username : undefined);
  useDispatchOnMount(getUserHouseholdId, isAxiosInitialized && user ? user : undefined);

  const match = useMatch('/forgot-password');

  if (match) {
    return <ForgotPassword />;
  }

  return (
    <AmplifyThemeProvider theme={theme}>
      <Authenticator components={CognitoComponents} formFields={formFields} hideSignUp>
        {isAxiosInitialized && user && (
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/qbias-survey" element={<QBiasSurvey />} />
              <Route path="/rt-survey" element={<RTSurvey />} />
              <Route path="/kyc-onboarding/*" element={<KycContainer />} />
              <Route element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/annual-review/:id" element={<Splash />} />
                <Route path="/annual-review/:id/kyc-review" element={<DemographicReview />} />
                <Route path="/annual-review/:id/rtq" element={<RtqReview />} />
                <Route path="/annual-review/:id/confirmation" element={<Confirmation />} />
                <Route path="/data-loader/*" element={<DataLoader />} />
                <Route path="/baselineplan" element={<Baseline />} />
                <Route path="/cashflow" element={<Cashflow />}>
                  <Route path="incomesummary" element={<IncomeSummary />} />
                  <Route path="monthovermonth" element={<MonthOverMonth />} />
                  <Route path="monthovermonth/:yearAndMonth" element={<MonthDetail />} />
                </Route>
                <Route path="/communications" element={<Communications />} />
                <Route path="/functions" element={<Functions />} />
                <Route path="/flowReport" element={<FlowReport />} />
                <Route path="/schedule" element={<CalendlyBooking />} />
                <Route path="/networth" element={<NetWorth />} />
                <Route path="/account" element={<Account />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/qscore" element={<QScore />} />
                <Route path="/projectedNetWorthReport" element={<ProjectedNetWorthReport />} />
                <Route path="/team" element={<Team />} />
                <Route path="/values" element={<Values />} />
                <Route path="/vault" element={<Vault />} />
              </Route>
            </Routes>
          </Suspense>
        )}
        {!isAxiosInitialized && <Loader />}
      </Authenticator>
    </AmplifyThemeProvider>
  );
};

export default App;
