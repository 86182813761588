import { compose } from 'ramda';
import { bffHouseholdApi } from './constants';
import { parseResponse, withData, withHttpMethod, TransactionDetail } from '@qwealth/qdata';

export const fetchTransactionDetail = async (
  householdId: string | number,
  yearAndMonth: string,
): Promise<TransactionDetail> => {
  const [year, month] = yearAndMonth.split('-');
  const response = await bffHouseholdApi(householdId)(`monthDetail/${year}/${month}`);
  const data = await parseResponse<TransactionDetail>(response);
  return data;
};

export const updateTransactionDetail = async (
  householdId: string | number,
  yearAndMonth: string,
  transactionDetail: TransactionDetail,
): Promise<void> => {
  const api = compose(
    withHttpMethod('PATCH'),
    withData(transactionDetail),
  )(bffHouseholdApi(householdId));
  const [year, month] = yearAndMonth.split('-');
  await api(`monthDetail/${year}/${month}`);
};
